import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';

export const PrivacyPageTemplate: React.FC<any> = ({ title, text }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageTitle>{title}</PageTitle>
      <div>
        {text.split('\n').map((paragraph) => (
          <p>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

const PrivacyPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PrivacyPageTemplate
        title={post.frontmatter.title}
        text={post.frontmatter.text}
      />
    </Layout>
  );
};

export default PrivacyPage;

export const query = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
  }
`;
